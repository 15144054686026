html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
}

div#app {
  height: 100%;
  width: 100%;
}

#accountBalances td {
  white-space: nowrap;
}

#accountBalances tr.totals td {
  font-weight: bold;
}

#accountBalances .account-balances-fixed-column {
  white-space: nowrap;
  background-color: rgb(255, 255, 255, 1) !important;
  position: sticky;
  left: 0;
  z-index: 1;
  font-weight: bold;
}

#accountBalances tr.account-balances-fixed-row {
  white-space: nowrap;
  background-color: rgb(255, 255, 255, 1) !important;
  position: sticky;
  top: 0;
}

.hover-icon .hover-icon-on,
.hover-icon:hover .hover-icon-off {
  display: none;
}

.hover-icon:hover .hover-icon-on {
  display: inline;
}
